import { gql } from '@apollo/client';

export const LoginMutation = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      refreshToken
      user {
        email
        firstName
        lastName
        role
      }
    }
  }
`;

export const InviteMutation = gql`
  mutation Invite($firstName: String!, $lastName: String!, $email: String!) {
    invite(firstName: $firstName, lastName: $lastName, email: $email) {
      status
      message
    }
  }
`;

export const SignUpOnInvite = gql`
  mutation SignUpOnInvite($email: String!, $code: String!, $password: String!) {
    signUpOnInvite(email: $email, code: $code, password: $password) {
      status
      message
    }
  }
`;

export const RefreshTokenMutation = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      user {
        email
        firstName
        lastName
        role
      }
    }
  }
`;

export const UploadOneFileMutation = gql`
mutation AddOneFile($file: String!, $name: String!) {
  addOneFile(file: $file, name: $name) {
    id
  }
}
`;

export const DeleteOneFileMutation = gql`
mutation DeleteOneFile($fileId: Int!) {
  deleteOneFile(fileId: $fileId) {
    id
  }
}
`;

export const UploadFileForUserMutation = gql`
mutation AddFileForUser($profileId: Int!, $file: String!, $name: String!, $type: String!) {
  addFileForUser(profileId: $profileId, file: $file, name: $name, type: $type) {
    id
  }
}
`;

export const DeleteFileForUserMutation = gql`
mutation DeleteFileForUser($profileId: Int!, $fileId: Int!) {
  deleteFileForUser(profileId: $profileId, fileId: $fileId) {
    id
  }
}
`;

export const ForgotPasswordMutation = gql`
mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email) {
    status
    message
  }
}
`;

export const ResetPasswordMutation = gql`
mutation ResetPassword($codeToken: String!, $email: String!, $password: String!) {
  resetPassword(codeToken: $codeToken, email: $email, password: $password) {
    status
    message
  }
}
`;

export const DeleteUserMutation = gql`
mutation DeleteOneUser($email: String!) {
  deleteOneUser(email: $email) {
    message
    status
  }
}
`;

export const PromoteOneUserToCompetitor = gql`
mutation PromoteOneUserToCompetitor($email: String!) {
  promoteOneUserToCompetitor(email: $email) {
    message
    status
  }
}
`;

export const UpdateOneUser = gql`
mutation UpdateOneUser($updateOneUserId: Int!, $email: String, $lastName: String, $firstName: String, $profile: ProfileCreateInput) {
  updateOneUser(id: $updateOneUserId, email: $email, lastName: $lastName, firstName: $firstName, profile: $profile) {
    status
  }
}
`;
