import React from 'react-native';
import { Formik } from 'formik';
import {
  FormControl, NativeBaseProvider,
} from 'native-base';
import {
  Button, Dialog, Portal, Text,
} from 'react-native-paper';
import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Picker } from '@react-native-picker/picker';
import { styles } from '../../screens/Common.style';
import { ModalContext } from '../../context/ModalContext';
import { CreateAchievement } from '../../gql/achievement/mutation';
import { FindOneEvent } from '../../gql/event/query';
import { computeWeightBasedOnCategorie } from '../../utils/utils';
import { AGE_CATEGORY, EVENT_PARTICIPANT_MODE } from '../../utils/constant';

function Achievement() {
  const {
    displayCreateAchievementModal,
    toggleCreateAchievementModal,
  } = useContext(ModalContext);
  const [createAchievement] = useMutation(CreateAchievement);

  const positions = [
    { label: 'Premier', value: 1 },
    { label: 'Deuxième', value: 2 },
    { label: 'Troisième', value: 3 },
    { label: 'Cinquième', value: 5 },
  ];

  const computeWeightCategories = (category, gender) => {
    const objCategorie = Object.values(AGE_CATEGORY)
      .find((c) => c.name === category);
    const weights = objCategorie.weights[gender.toLowerCase()];
    weights.push(weights[weights.length - 1]);
    return weights.map((e, i) => (i !== weights.length - 1 ? `-${e}` : `+${e}`));
  };

  return displayCreateAchievementModal?.display ? (
    <Formik
      initialValues={{
        rank: 1,
        category: displayCreateAchievementModal?.categories[0],
        weightCategory: computeWeightBasedOnCategorie(
          displayCreateAchievementModal?.weight,
          displayCreateAchievementModal?.gender,
          displayCreateAchievementModal?.categories[0],
        ),
        mode: Object.keys(EVENT_PARTICIPANT_MODE)[0],
      }}
      validateOnChange
      onSubmit={async (values) => {
        await createAchievement({
          variables: {
            eventId: displayCreateAchievementModal.eventId,
            rank: Number(values.rank),
            category: values.category,
            weightCategory: values.weightCategory,
            userId: displayCreateAchievementModal.userId,
            mode: values.mode,
          },
          refetchQueries: [
            FindOneEvent, // DocumentNode object parsed with gql
            'findOneEvent', // Query name
          ],
        });
        toggleCreateAchievementModal();
      }}
    >
      {({
        handleChange, handleSubmit, values, errors, isValid,
      }) => (
        <Dialog
          visible={displayCreateAchievementModal}
          dismissable
          onDismiss={toggleCreateAchievementModal}
        >
          <Dialog.Title>Enregistrer un résultat</Dialog.Title>
          <Dialog.Content>
            <FormControl style={styles.field}>
              <FormControl.Label>Rang</FormControl.Label>
              <Picker
                style={{ minHeight: 40 }}
                onValueChange={handleChange('rank')}
                selectedValue={values.rank}
              >
                {positions
                  .map((k) => <Picker.Item label={k.label} value={k.value} />)}
              </Picker>
            </FormControl>
            {!!errors.rank && <Text style={styles.error}>{errors.rank}</Text>}
            <FormControl style={styles.field}>
              <FormControl.Label>Catégorie</FormControl.Label>
              <Picker
                style={{ minHeight: 40 }}
                selectedValue={values.category}
                onValueChange={handleChange('category')}
              >
                {displayCreateAchievementModal.categories
                  .map((k) => <Picker.Item label={k} value={k} />)}
              </Picker>
            </FormControl>
            <FormControl style={styles.field}>
              <FormControl.Label>Catégorie de poids</FormControl.Label>
              <Picker
                style={{ minHeight: 40 }}
                selectedValue={values.weightCategory}
                onValueChange={
                  handleChange('weightCategory')
                }
              >
                {computeWeightCategories(values.category, displayCreateAchievementModal.gender)
                  .map((k) => <Picker.Item label={k} value={k} />)}
              </Picker>
            </FormControl>
            <FormControl style={styles.field}>
              <FormControl.Label>Mode</FormControl.Label>
              <Picker
                style={{ minHeight: 40 }}
                selectedValue={values.mode}
                onValueChange={
                  handleChange('mode')
                }
              >
                {Object.entries(EVENT_PARTICIPANT_MODE)
                  .map((k) => <Picker.Item label={k[1]} value={k[0]} />)}
              </Picker>
            </FormControl>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={toggleCreateAchievementModal}>
              Annuler
            </Button>
            <Button disabled={!isValid} onPress={handleSubmit}>
              Envoyer
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </Formik>
  ) : null;
}

export default function CreateAchievementModal() {
  return (
    <Portal>
      <NativeBaseProvider>
        <Achievement />
      </NativeBaseProvider>
    </Portal>
  );
}
