import { NativeBaseProvider } from 'native-base';
import React, { useContext, useState } from 'react';
import { Dialog, Portal } from 'react-native-paper';
import { Picker } from '@react-native-picker/picker';
import SelectBox from 'react-native-multi-selectbox';
import { xorBy } from 'lodash';
import { ModalContext } from '../../context/ModalContext';

function Filters() {
  const {
    displayFiltersModal,
    toggleFiltersModal,
  } = useContext(ModalContext);

  const [refresh, setRefresh] = useState(0);

  return (
    <Dialog
      visible={displayFiltersModal.display}
      dismissable
      onDismiss={toggleFiltersModal}
    >
      <Dialog.Title>
        {displayFiltersModal.title}
      </Dialog.Title>
      <Dialog.ScrollArea style={{ maxHeight: '80vh' }}>
        {displayFiltersModal.filters?.map((filter) => {
          switch (filter.component) {
            case 'SelectBox':
              return (
                <SelectBox
                  label={filter.name}
                  options={filter.values}
                  selectedValues={filter.value.current}
                  onMultiSelect={(item) => {
                    filter.value.current = xorBy(filter.value.current, [item]);
                    setRefresh((prev) => prev + 1);
                  }}
                  onTapClose={(item) => {
                    filter.value.current = xorBy(filter.value.current, [item]);
                    setRefresh((prev) => prev + 1);
                  }}
                  isMulti={filter.multiSelect ?? false}
                />
              );
            default:
              return (
                <Picker
                  style={{ minHeight: 40 }}
                  selectedValue={filter.value}
                  onValueChange={(value) => filter.setValue(value)}
                >
                  {filter.values
                    .map((k) => <Picker.Item label={k.item} value={k.id} />)}
                </Picker>
              );
          }
        })}
      </Dialog.ScrollArea>
    </Dialog>
  );
}

export default function ManageFiltersModal() {
  return (
    <Portal>
      <NativeBaseProvider>
        <Filters />
      </NativeBaseProvider>
    </Portal>
  );
}
