import React from 'react';
import { ScrollView } from 'native-base';
import {
  Avatar, Card, List,
} from 'react-native-paper';

function EventParticipantsTab({ event, disabled = true }) {
  return (
    <ScrollView keyboardShouldPersistTaps="always">
      <Card.Content>
        {event?.players.length > 0
          ? event?.players.map((p) => (
            <List.Item
              title={`${p.player.firstName} ${p.player.lastName}`}
              left={(props) => (
                <Avatar.Image
                  {...props}
                  source={{
                    uri: p.player.profile.pictureThumbnail,
                  }}
                />
              )}
            />
          ))
          : null}
      </Card.Content>
    </ScrollView>
  );
}

export default EventParticipantsTab;
