import {
  compareDesc, differenceInDays, formatDistance, isFuture,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import React, { useContext } from 'react';
import {
  Card, Chip, IconButton, useTheme,
} from 'react-native-paper';
import Animated, {
  Easing,
  interpolateColor, useAnimatedStyle, useSharedValue, withRepeat, withSequence, withTiming,
} from 'react-native-reanimated';
import { Image, useToast } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { useMutation } from '@apollo/client';
import Flag from 'react-native-flags';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { View } from 'react-native';
import { FindManyEvents } from '../../../gql/event/query';
import { dateTimeOptions, SCREEN_NAMES } from '../../../utils/constant';
import { ModalContext } from '../../../context/ModalContext';
import { JoinEvent, LeaveEvent } from '../../../gql/event/mutation';
import { ISOCODE } from '../../../utils/isocode';
import { styles } from '../../Common.style';

const TIME = 2000;
const EASING = Easing.elastic(0.5);

function EventCard({
  user, event, today, isSuperAdmin, userCategories, handlePlayersOnEvent,
}) {
  window._frameTimestamp = null;
  const {
    toggleInformationModal,
    togglePictureModal,
    toggleEventAchievementsModal,
  } = useContext(ModalContext);
  const toast = useToast();
  const navigation = useNavigation();
  const [joinEvent] = useMutation(JoinEvent);
  const [leaveEvent] = useMutation(LeaveEvent);
  const theme = useTheme();

  const isEventTerminated = event.status === 'CLOSED';

  const progress = useSharedValue(0);

  const registrationWarning = useAnimatedStyle(() => ({
    backgroundColor: interpolateColor(
      progress.value,
      [0, 1],
      [theme.colors.warning.light, 'transparent'],
      'RGB',
    ),
  }));

  progress.value = withRepeat(withSequence(withTiming(1, {
    duration: TIME,
    easing: EASING,
  }), withTiming(0, {
    duration: TIME,
    easing: EASING,
  })), 5000);

  const splittedLocation = event.location.split(',');
  const country = splittedLocation[splittedLocation.length - 1].trim();
  const registerEndAtDate = new Date(event.registerEndAt);
  const endAtDate = new Date(event.endAt);

  const hasAlreadyJoined = event.players?.map((p) => p.player.id).includes(user?.id);
  const canJoin = !hasAlreadyJoined
              && user?.status === 'COMPETITOR'
              && userCategories.some((c) => event.categories.includes(c))
              && isFuture(registerEndAtDate);

  const eventSubtitle = compareDesc(today, registerEndAtDate) === 1
    ? `Fin des inscriptions ${formatDistance(registerEndAtDate, today, { addSuffix: true, locale: fr })}`
    : compareDesc(endAtDate, today) === 1 && isEventTerminated
      ? 'Évènement terminé'
      : 'Inscriptions terminées';

  const lessThan14days = compareDesc(today, registerEndAtDate) === 1
    && differenceInDays(registerEndAtDate, today) < 14
    && ['OPEN', 'SCHEDULED'].includes(event.status);

  const redHighlight = isEventTerminated || lessThan14days || compareDesc(today, registerEndAtDate) !== 1;

  return (
    <Animated.View style={lessThan14days
      ? registrationWarning
      : isEventTerminated
        ? styles.cardTerminated : undefined}
    >
      <Card
        key={event.name}
        elevation={0.5}
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <Card.Title title={eventSubtitle} titleVariant="labelMedium" titleStyle={redHighlight ? styles.error : undefined} />
        <Card.Title
          key={`${event.name}-title`}
          titleStyle={isEventTerminated ? styles.cardDisabled : undefined}
          subtitleStyle={isEventTerminated ? styles.cardDisabled : undefined}
          title={event.name}
          subtitle={`Date: ${new Date(event.startAt).toLocaleDateString(
            'fr-FR',
            dateTimeOptions,
          )}\nLieu: ${event.location}\nCatégories: ${event.categories?.join(',')}`}
          subtitleNumberOfLines={6}
          left={() => (
            <TouchableOpacity onPress={() => togglePictureModal({ eventId: event.id, isInfoMedia: false })}>
              <Image
                style={{ height: '50px' }}
                source={{ uri: event.mediaThumbnail }}
              />
            </TouchableOpacity>
          )}
          right={(props) => (
            <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity onPress={() => togglePictureModal({ eventId: event.id, isInfoMedia: true })}>
                <Image
                  {...props}
                  style={{ height: '50px', width: '50px' }}
                  source={{ uri: event.infoMediaThumbnail }}
                />
              </TouchableOpacity>
              <Flag
                {...props}
                type="flat"
                code={Object.entries(ISOCODE).find((e) => e[1] === country)?.[0]}
                size={32}
              />
            </View>
          )}
        />
        <Card.Actions key={`${event.name}-actions`} style={{ gap: '5px' }}>
          {event.status === 'CLOSED'
            ? (
              <Chip icon="podium" onPress={() => toggleEventAchievementsModal(event.id, event.name)}>
                Résultats
              </Chip>
            )
            : (
              <>
                <Chip
                  disabled={event.players.filter((p) => p.isConfirmed).length === 0}
                  icon="karate"
                  onPress={() => handlePlayersOnEvent(event.id, 'REMOVE')}
                >
                  {`${event.players.filter((p) => p.isConfirmed).length} ${event.players.length > 1 ? 'confirmés' : 'confirmé'}`}
                </Chip>
                <Chip
                  disabled={event.players.filter((p) => !p.isConfirmed).length === 0}
                  icon="progress-clock"
                  onPress={() => handlePlayersOnEvent(event.id, 'ADD')}
                >
                  {`${event.players.filter((p) => !p.isConfirmed).length} en attente`}
                </Chip>
                { hasAlreadyJoined
                  ? (
                    <Chip
                      key={`${event.name}-button`}
                      sx={{ marginRight: '5px' }}
                      mode="outlined"
                      onPress={async () => {
                        const status = await leaveEvent(
                          {
                            variables: { eventId: Number(event.id) },
                            refetchQueries: [
                              FindManyEvents, // DocumentNode object parsed with gql
                              'findManyEvents', // Query name
                            ],
                          },
                        );
                        toast.show(status.message);
                      }}
                    >
                      Se désinscrire
                    </Chip>
                  )
                  : (
                    <>
                      <Chip
                        key={`${event.name}-button`}
                        mode="outlined"
                        disabled={!canJoin}
                        onPress={async () => {
                          const status = await joinEvent(
                            {
                              variables: { joinEventId: Number(event.id) },
                              refetchQueries: [
                                FindManyEvents, // DocumentNode object parsed with gql
                                'findManyEvents', // Query name
                              ],
                            },
                          );
                          toast.show(status.message);
                        }}
                      >
                        S'inscrire
                      </Chip>
                      { user?.status !== 'COMPETITOR'
                      && (
                      <IconButton
                        icon="message-alert"
                        iconColor={user?.status === 'REVIEW_PENDING' ? 'orange' : 'red'}
                        onPress={() => toggleInformationModal({
                          title: 'Information importante',
                          message: user?.status === 'REVIEW_PENDING'
                            ? 'Tous les documents sont présents, une notification a été addressée aux administrateurs afin de valider leurs conformités.'
                            : `
Afin de s\'inscrire aux compétitions, il est nécessaire d\'ajouter l\'ensemble des documents requis depuis l\'onglet "Documents"
Un administrateur vérifiera ensuite que tous les documents sont conformes.
            `,
                        })}
                      />
                      )}
                    </>
                  )}

              </>
            )}
          <IconButton
            icon={isSuperAdmin ? 'application-edit' : 'eye'}
            onPress={() => navigation.navigate(
              { name: SCREEN_NAMES.EVENT, params: { eventId: event.id } },
            )}
          />

        </Card.Actions>
      </Card>
    </Animated.View>
  );
}

export default EventCard;
