import React, { useContext, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ScrollView, View } from 'native-base';
import { Platform, Pressable } from 'react-native';
import {
  ActivityIndicator,
  Button, Card, Dialog, List,
  Portal,
} from 'react-native-paper';
import { getDocumentAsync } from 'expo-document-picker';
import * as base64ToBlob from 'b64-to-blob';
import { FindManyGlobalFiles, FindOneGlobalFile } from '../../../gql/file/query';
import { AuthContext } from '../../../context/AuthContext';
import { ROLE_NAMES } from '../../../utils/constant';
import { ModalContext } from '../../../context/ModalContext';
import ConfirmationModal from '../../../modals/Confirmation/ConfirmationModal';
import {
  DeleteOneFileMutation,
  UploadOneFileMutation,
} from '../../../gql/user/mutation';
import { getFileType } from '../../../utils/utils';
import DocumentViewerModal from '../../../modals/DocumentViewer/DocumentViewerModal';
import DocumentEditorModal from '../../../modals/DocumentEditor/DocumentEditorModal';

function GeneralTab({ disabled = true }) {
  const { authState } = useContext(AuthContext);
  const {
    displayDocumentViewerModal,
    toggleDocumentViewerModal,
    displayConfirmationModal,
    toggleConfirmationModal,
    displayDocumentEditorModal,
    toggleDocumentEditorModal,
  } = useContext(ModalContext);

  const [isLoading, setIsLoading] = useState(false);

  const user = undefined;
  const { data, refetch } = useQuery(FindManyGlobalFiles);
  const files = data?.findManyGlobalFiles;
  const [displayFileTypeModal, setDisplayFileTypeModal] = useState(false);
  const [addOneFile] = useMutation(UploadOneFileMutation);
  const [deleteOneFile] = useMutation(DeleteOneFileMutation);
  const [loadFile] = useLazyQuery(
    FindOneGlobalFile,
    {
      fetchPolicy: 'network-only',
    },
  );

  const viewFile = async (fileId, filename) => {
    setIsLoading(true);
    const fileData = await loadFile({
      variables: {
        fileId,
      },
    });
    const base64file = fileData?.data?.findOneGlobalFile?.file;
    toggleDocumentViewerModal({
      fileId, filename, isWeb: Platform.OS === 'web', file: base64file,
    });
    setIsLoading(false);
  };

  const editFile = async (fileId, filename) => {
    setIsLoading(true);
    const fileData = await loadFile({
      variables: {
        fileId,
      },
    });
    const base64file = fileData?.data?.findOneGlobalFile?.file;
    toggleDocumentEditorModal({
      fileId, filename, isWeb: Platform.OS === 'web', file: base64file,
    });
    setIsLoading(false);
  };

  const downloadFile = async (fileId, filename) => {
    setIsLoading(true);
    const fileData = await loadFile({
      variables: {
        fileId,
      },
    });
    const base64file = fileData.data.findOneGlobalFile.file;
    if (Platform.OS === 'web') {
      const type = getFileType(base64file);
      const cleanedBase64 = base64file.split(',')[1];
      // generate a blob, then a file and then save the file.
      const blob = base64ToBlob(cleanedBase64, type);
      const file = new File([blob], filename, { type });

      import('file-saver').then((fs) => {
        fs.default.saveAs(file);
      });
    } else {
      const FileSystem = React.lazy(() => import('expo-file-system'));
      FileSystem.writeAsStringAsync(filename, base64file, { encoding: 'base64' });
    }
    setIsLoading(false);
  };

  const uploadFile = async () => {
    const result = await getDocumentAsync({
      type: ['application/pdf', 'image/jpeg', 'application/msword'],
    });
    if (result.type === 'success') {
      await addOneFile({
        variables: {
          file: result.uri,
          name: result.name,
        },
      });
      refetch();

      setDisplayFileTypeModal(false);
    }
  };

  const deleteFile = async (fileId) => {
    if (fileId) {
      await deleteOneFile({
        variables: {
          fileId,
        },
      });
      refetch();
    }
  };

  const leftComponent = (f) => (
    <Pressable onPress={() => viewFile(f.id, f.name)}>
      <List.Icon icon="eye" />
    </Pressable>
  );

  const rightComponent = (f) => (
    <View style={{ flexDirection: 'row', gap: 10 }}>
      {(authState.role === ROLE_NAMES.SUPERADMIN) && (
      <Pressable onPress={() => editFile(f.id, f.name)}>
        <List.Icon icon="image-edit" />
      </Pressable>
      )}
      <Pressable onPress={() => downloadFile(f.id, f.name)}>
        <List.Icon icon="download" />
      </Pressable>
      {(authState.role === ROLE_NAMES.SUPERADMIN) && (
      <Pressable onPress={() => toggleConfirmationModal({
        title: 'Confirmation',
        message: 'Voulez-vous vraiment supprimer ce document ?',
        handleSubmit: () => deleteFile(f.id),
      })}
      >
        <List.Icon icon="trash-can" />
      </Pressable>
      )}
    </View>
  );

  return (
    <>
      {displayConfirmationModal.display && <ConfirmationModal />}
      {displayDocumentViewerModal.display && <DocumentViewerModal />}
      {displayDocumentEditorModal.display && <DocumentEditorModal />}
      <Portal>
        <Dialog visible={displayFileTypeModal} onDismiss={() => setDisplayFileTypeModal(false)}>
          <Dialog.Title>Téléverser un document</Dialog.Title>
          {/* <Dialog.Content>
            <Picker
              style={{ minHeight: 40 }}
              selectedValue={documentType}
              onValueChange={(itemValue) => setDocumentType(itemValue)}
            >
              {fileTypes.map((k) => <Picker.Item label={k[1].name} value={k[0]} />)}
            </Picker>
          </Dialog.Content> */}
          <Dialog.Actions>
            <Button onPress={() => uploadFile()}>Parcourir</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <ScrollView>
        <Card.Content>
          {(authState.role === ROLE_NAMES.SUPERADMIN) && (
          <Card.Actions>
            <Button
              onPress={() => setDisplayFileTypeModal(true)}
            >
              Ajouter un document
            </Button>
          </Card.Actions>
          )}
          <ActivityIndicator animating={isLoading} />
          {files?.map((f) => (
            <List.Item
              key={f.id}
              onPress={() => viewFile(f.id, f.name)}
              title={f.name}
              left={() => leftComponent(f)}
              right={() => rightComponent(f)}
            />
          ))}
        </Card.Content>
      </ScrollView>
    </>
  );
}

export default GeneralTab;
