import React, { createContext, useState } from 'react';

const ModalContext = createContext();
const { Provider } = ModalContext;

function ModalProvider({ children }) {
  const [displayWeightModal, setDiplayWeightModal] = useState({
    display: false,
    profileId: undefined,
  });
  const [displayFiltersModal, setDisplayFiltersModal] = useState({
    display: false,
    title: undefined,
    filters: {},
  });
  const [displayCreateAchievementModal, setDisplayCreateAchievementModal] = useState({
    display: false,
    userId: undefined,
    eventId: undefined,
    categories: undefined,
    gender: undefined,
    weight: undefined,
  });
  const [displayCreateAnnouncementModal, setDisplayCreateAnnouncementModal] = useState(false);
  const [displayUsersOnAnnouncementModal, setDisplayUsersOnAnnouncementModal] = useState(false);
  const [displayEventAchievementsModal, setDisplayEventAchievementsModal] = useState({
    display: false,
    eventId: undefined,
  });
  const [displayCreateEventModal, setDisplayCreateEventModal] = useState(false);
  const [displayDocumentViewerModal, setDisplayDocumentViewerModal] = useState({
    display: false,
    fileId: undefined,
    filename: undefined,
    isWeb: false,
    file: undefined,
  });
  const [displayDocumentEditorModal, setDisplayDocumentEditorModal] = useState({
    display: false,
    fileId: undefined,
    filename: undefined,
    isWeb: false,
    file: undefined,
  });
  const [displayInformationModal, setDisplayInformationModal] = useState({
    display: false,
    title: undefined,
    message: undefined,
  });
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState({
    display: false,
    title: undefined,
    message: undefined,
    handleSubmit: () => undefined,
  });
  const [displayPlayersOnEventModal, setDisplayPlayersOnEventModal] = useState({
    display: false,
    action: undefined,
  });
  const [displayPictureModal, setDisplayPictureModal] = useState({
    display: false,
    eventId: undefined,
    isInfoMedia: false,
  });

  const toggleWeightModal = (props) => {
    setDiplayWeightModal({
      display: !displayWeightModal.display,
      profileId: props?.profileId,
    });
  };

  const toggleCreateAchievementModal = (props) => {
    setDisplayCreateAchievementModal({
      display: !displayCreateAchievementModal.display,
      eventId: props?.eventId,
      userId: props?.userId,
      categories: props?.categories,
      gender: props?.gender,
      weight: props?.weight,
    });
  };

  const toggleCreateAnnouncementModal = () => {
    setDisplayCreateAnnouncementModal(!displayCreateAnnouncementModal);
  };

  const toggleCreateEventModal = () => {
    setDisplayCreateEventModal(!displayCreateEventModal);
  };

  const toggleUsersOnAnnouncementModal = () => {
    setDisplayUsersOnAnnouncementModal(!displayUsersOnAnnouncementModal);
  };

  const toggleEventAchievementsModal = (eventId, eventName) => {
    setDisplayEventAchievementsModal({
      display: !displayEventAchievementsModal.display,
      eventId: eventId ?? undefined,
      eventName: eventName ?? undefined,
    });
  };

  const toggleFiltersModal = (props) => {
    setDisplayFiltersModal((prev) => ({
      display: props?.display !== undefined ? props?.display : !displayFiltersModal.display,
      title: props?.title ?? prev.title,
      filters: props?.filters ?? prev.filters,
    }));
  };

  const toggleDocumentViewerModal = (props) => {
    setDisplayDocumentViewerModal({
      display: !displayDocumentViewerModal.display,
      fileId: props?.fileId,
      filename: props?.filename,
      isWeb: props?.isWeb,
      file: props?.file,
    });
  };

  const toggleDocumentEditorModal = (props) => {
    setDisplayDocumentEditorModal({
      display: !displayDocumentEditorModal.display,
      fileId: props?.fileId,
      filename: props?.filename,
      isWeb: props?.isWeb,
      file: props?.file,
    });
  };

  const toggleInformationModal = (props) => {
    setDisplayInformationModal({
      display: !displayInformationModal.display,
      title: props?.title,
      message: props?.message,
    });
  };

  const toggleConfirmationModal = (props) => {
    setDisplayConfirmationModal({
      display: !displayConfirmationModal.display,
      title: props?.title,
      message: props?.message,
      handleSubmit: props?.handleSubmit,
    });
  };

  const togglePlayersOnEventModal = (props) => {
    setDisplayPlayersOnEventModal({
      display: !displayPlayersOnEventModal.display,
      action: props?.action,
    });
  };

  const togglePictureModal = (props) => {
    setDisplayPictureModal({
      display: !displayPictureModal.display,
      eventId: props?.eventId,
      isInfoMedia: props?.isInfoMedia,
    });
  };

  return (
    <Provider
      value={{
        displayWeightModal,
        toggleWeightModal,
        displayCreateAnnouncementModal,
        toggleCreateAnnouncementModal,
        displayUsersOnAnnouncementModal,
        toggleUsersOnAnnouncementModal,
        displayCreateEventModal,
        toggleCreateEventModal,
        displayDocumentViewerModal,
        toggleDocumentViewerModal,
        displayInformationModal,
        toggleInformationModal,
        displayConfirmationModal,
        toggleConfirmationModal,
        displayPlayersOnEventModal,
        togglePlayersOnEventModal,
        displayDocumentEditorModal,
        toggleDocumentEditorModal,
        displayPictureModal,
        togglePictureModal,
        displayFiltersModal,
        toggleFiltersModal,
        displayCreateAchievementModal,
        toggleCreateAchievementModal,
        displayEventAchievementsModal,
        toggleEventAchievementsModal,
      }}
    >
      {children}
    </Provider>
  );
}

export { ModalContext, ModalProvider };
