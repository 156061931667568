import React, { useContext } from 'react';
import { ScrollView } from 'native-base';
import {
  Avatar,
  Card, Chip, Divider, IconButton, List,
  Text,
} from 'react-native-paper';
import { useMutation } from '@apollo/client';
import { AuthContext } from '../../../context/AuthContext';
import { EVENT_PARTICIPANT_MODE, ROLE_NAMES } from '../../../utils/constant';
import { ModalContext } from '../../../context/ModalContext';
import { computeCategorie } from '../../../utils/utils';
import { DeleteAchievement } from '../../../gql/achievement/mutation';
import { FindOneEvent } from '../../../gql/event/query';

function EventResultsTab({ event, disabled = false }) {
  const { authState } = useContext(AuthContext);
  const { toggleCreateAchievementModal } = useContext(ModalContext);

  const [deleteAchievement] = useMutation(DeleteAchievement);

  return (
    <ScrollView keyboardShouldPersistTaps="always">
      {disabled ? (
        <Card.Content>
          <Text>
            {' '}
            La compétition n'est pas terminée, l'ajout de résultats ne sera possible que lorsque la compétition est terminée.
          </Text>
        </Card.Content>
      ) : (
        <Card.Content>
          {event?.players.length > 0
            ? event?.players.map((p) => (p.achievements.length > 0
              ? (
                <>
                  <Divider />
                  <List.Accordion
                    title={`${p.player.firstName} ${p.player.lastName}`}
                    expanded
                    left={(props) => (p.player.profile.pictureThumbnail
                      ? (
                        <Avatar.Image
                          {...props}
                          source={{
                            uri: p.player.profile.pictureThumbnail,
                          }}
                        />
                      )
                      : (
                        <Avatar.Text
                          size={50}
                          label={`${p.player.firstName[0]}${p.player.lastName[0]}`}
                        />
                      ))}
                    right={(props) => (
                      authState.userId === p.player.id
                    || authState.role === ROLE_NAMES.SUPERADMIN && (
                    <IconButton
                      {...props}
                      icon="plus"
                      onPress={() => toggleCreateAchievementModal({
                        eventId: Number(event.id),
                        userId: Number(p.player.id),
                        categories: computeCategorie(new Date(p.player.profile.dateOfBirth)),
                        gender: p.player.profile.gender,
                        weight: p.player.profile.weights[0].value,
                      })}
                    />
                    ))}
                  >
                    {p.achievements.map((achievement) => (
                      <List.Item
                        left={(props) => (achievement.rank === 1
                          ? (
                            <IconButton
                              {...props}
                              iconColor="#edcb0f"
                              icon={{ uri: require('../../../assets/medal-first-place-stroke-rounded.svg') }}
                            />
                          )
                          : achievement.rank === 2
                            ? (
                              <IconButton
                                {...props}
                                iconColor="#c0c0c0"
                                icon={{ uri: require('../../../assets/medal-second-place-stroke-rounded.svg') }}
                              />
                            )
                            : achievement.rank === 3
                              ? (
                                <IconButton
                                  {...props}
                                  iconColor="#ce8946"
                                  icon={{ uri: require('../../../assets/medal-third-place-stroke-rounded.svg') }}
                                />
                              )
                              : (
                                <IconButton
                                  {...props}
                                  icon="numeric-5-circle"
                                />
                              ))}
                        right={(props) => (
                          <>
                            <Chip {...props}>{achievement.category}</Chip>
                            <Chip {...props}>
                              {achievement.weightCategory}
                              {' '}
                              kg
                            </Chip>
                            <Chip {...props}>{Object.entries(EVENT_PARTICIPANT_MODE).find((item) => item[0] === achievement.mode)[1]}</Chip>
                            {(authState.userId === p.player.id || authState.role === ROLE_NAMES.SUPERADMIN)
                            && (
                            <IconButton

                              {...props}
                              icon="delete"
                              onPress={() => {
                                deleteAchievement({
                                  variables: {
                                    id: achievement.id,
                                  },
                                  refetchQueries: [
                                    FindOneEvent, // DocumentNode object parsed with gql
                                    'findOneEvent', // Query name
                                  ],
                                });
                              }}
                            />
                            )}
                          </>
                        )}
                      />
                    ))}
                  </List.Accordion>
                </>
              )
              : (
                <>
                  <Divider />
                  <List.Item
                    title={`${p.player.firstName} ${p.player.lastName}`}
                    left={(props) => (p.player.profile.pictureThumbnail
                      ? (
                        <Avatar.Image
                          {...props}
                          source={{
                            uri: p.player.profile.pictureThumbnail,
                          }}
                        />
                      )
                      : (
                        <Avatar.Text
                          size={50}
                          label={`${p.player.firstName[0]}${p.player.lastName[0]}`}
                        />
                      ))}
                    right={(props) => (
                      (authState.userId === p.player.id
                    || authState.role === ROLE_NAMES.SUPERADMIN) && (
                    <IconButton
                      {...props}
                      icon="plus"
                      onPress={() => toggleCreateAchievementModal({
                        eventId: Number(event.id),
                        userId: Number(p.player.id),
                        categories: computeCategorie(new Date(p.player.profile.dateOfBirth)),
                        gender: p.player.profile.gender,
                        weight: p.player.profile.weights[0].value,
                      })}
                    />
                      )
                    )}
                  />
                </>
              )))
            : null}
        </Card.Content>
      )}
    </ScrollView>
  );
}

export default EventResultsTab;
