import { gql } from '@apollo/client';

export const FindManyFilesForUserQuery = gql`
  query FindManyFilesForUser($email: String!) {
    findManyFilesForUser(email: $email) {
      name
      type
      id
    }
  }
`;

export const FindManyGlobalFiles = gql`
  query FindManyGlobalFiles {
    findManyGlobalFiles {
      name
      id
    }
  }
`;

export const FindOneGlobalFile = gql`
  query FindOneGlobalFile($fileId: Int!) {
    findOneGlobalFile(fileId: $fileId) {
      name
      file
      id
    }
  }
`;

export const FindOneFileForUser = gql`
  query FindOneFileForUser($email: String!, $fileId: Int!) {
    findOneFileForUser(email: $email, fileId: $fileId) {
      name
      type
      file
      id
    }
  }
`;
