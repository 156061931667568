import { gql } from '@apollo/client';

export const FindMyAnnouncements = gql`
query FindMyAnnouncements {
    findMyAnnouncements {
      id
      message
      startDate
      endDate
      title
    }
  }
`;

export const FindOneAnnouncement = gql`
query FindOneAnnouncement($findOneAnnouncementId: Int!) {
  findOneAnnouncement(id: $findOneAnnouncementId) {
    title
    recipients {
      hasBeenRead
      user {
        firstName
        lastName
      }
    }
  }
}
`;

export const FindManyAnnouncements = gql`
query FindManyAnnouncements($endDate: DateTime!) {
  findManyAnnouncements(endDate: $endDate) {
    hasBeenRead
    announcement {
      id
      title
      message
      startDate
      endDate
      author {
        firstName
        lastName
        profile {
          pictureThumbnail
        }
      }
    }
  }
}
`;
