import * as React from 'react';
import { BottomNavigation } from 'react-native-paper';
import { NativeBaseProvider } from 'native-base';
import GeneralTab from './DocumentTabs/GeneralTab';

function Document({ disabled = false }) {
  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    {
      key: 'general',
      title: 'General',
      focusedIcon: 'notebook-edit',
      unfocusedIcon: 'notebook-edit-outline',
    },
  ]);

  const renderScene = BottomNavigation.SceneMap({
    general: () => <GeneralTab />,
  });

  return (
    <BottomNavigation
      navigationState={{ index, routes }}
      onIndexChange={setIndex}
      renderScene={renderScene}
    />
  );
}

export default function DocumentScreen() {
  return (
    <NativeBaseProvider>
      <Document />
    </NativeBaseProvider>
  );
}
