import React from 'react-native';
import {
  NativeBaseProvider, ScrollView,
} from 'native-base';
import {
  ActivityIndicator,
  Avatar,
  Button, Chip, Dialog, Divider, IconButton, List, MD2Colors, Portal, SegmentedButtons,
} from 'react-native-paper';
import { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ModalContext } from '../../context/ModalContext';
import { FindManyAchievementsForOneEvent } from '../../gql/achievement/query';
import { EVENT_PARTICIPANT_MODE } from '../../utils/constant';

function EventAchievement() {
  const {
    displayEventAchievementsModal,
    toggleEventAchievementsModal,
  } = useContext(ModalContext);

  const { data, loading, error } = useQuery(
    FindManyAchievementsForOneEvent,
    { variables: { id: Number(displayEventAchievementsModal?.eventId) } },
  );

  const [filters, setFilters] = useState([1, 2, 3, 5]);

  return loading ? <ActivityIndicator animating color={MD2Colors.red800} /> : (
    <Dialog
      visible={displayEventAchievementsModal.display}
      onDismiss={() => toggleEventAchievementsModal(undefined)}
    >
      <Dialog.Title>
        {`Résultats ${displayEventAchievementsModal.eventName}`}
      </Dialog.Title>
      <Dialog.ScrollArea style={{ maxHeight: '80vh' }}>
        <SegmentedButtons
          value={filters}
          multiSelect
          style={{ paddingBottom: '5px' }}
          onValueChange={(e) => setFilters(e)}
          buttons={[
            {
              value: 1,
              label: '1er',
            },
            {
              value: 2,
              label: '2eme',
            },
            {
              value: 3,
              label: '3eme',
            },
            {
              value: 5,
              label: '5eme',
            },
          ]}
        />
        <ScrollView>
          {data?.findManyAchievementsForOneEvent?.filter((achievement) => filters.includes(achievement.rank))
            .sort((a, b) => a.rank > b.rank)
            .map((achievement) => (
              <>
                <Divider bold />
                <List.Accordion
                  title={`${achievement.playerOnEvent.player.firstName} ${achievement.playerOnEvent.player.lastName}`}
                  style={{ backgroundColor: 'transparent' }}
                  expanded
                  right={(props) => (
                    <Avatar.Image
                      {...props}
                      source={{
                        uri: achievement.playerOnEvent.player.profile.pictureThumbnail,
                      }}
                    />
                  )}
                >
                  <List.Item
                    right={(props) => (
                      <>
                        <Chip {...props}>{achievement.category}</Chip>
                        <Chip {...props}>
                          {achievement.weightCategory}
                          {' '}
                          kg
                        </Chip>
                        <Chip {...props}>{Object.entries(EVENT_PARTICIPANT_MODE).find((item) => item[0] === achievement.mode)[1]}</Chip>
                        { achievement.rank === 1
                          ? (
                            <IconButton
                              {...props}
                              iconColor="#edcb0f"
                              icon={{ uri: require('../../assets/medal-first-place-stroke-rounded.svg') }}
                            />
                          )
                          : achievement.rank === 2
                            ? (
                              <IconButton
                                {...props}
                                iconColor="#c0c0c0"
                                icon={{ uri: require('../../assets/medal-second-place-stroke-rounded.svg') }}
                              />
                            )
                            : achievement.rank === 3
                              ? (
                                <IconButton
                                  {...props}
                                  iconColor="#ce8946"
                                  icon={{ uri: require('../../assets/medal-third-place-stroke-rounded.svg') }}
                                />
                              )
                              : (
                                <IconButton
                                  {...props}
                                  icon="numeric-5-circle"
                                />
                              )}
                      </>
                    )}
                  />
                </List.Accordion>
              </>
            ))}
        </ScrollView>
      </Dialog.ScrollArea>
      <Dialog.Actions>
        <Button onPress={() => toggleEventAchievementsModal(undefined)}>
          Fermer
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}

export default function EventAchievementModal() {
  return (
    <Portal>
      <NativeBaseProvider>
        <EventAchievement />
      </NativeBaseProvider>
    </Portal>
  );
}
