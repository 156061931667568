import { gql } from '@apollo/client';

export const FindMyAchievements = gql`
query FindMyAchievements {
    findMyAchievements {
      id
      rank
      category
      weightCategory
    }
  }
`;

export const FindManyAchievementsForOneEvent = gql`
query FindManyAchievementsForOneEvent($id: Int!) {
  findManyAchievementsForOneEvent(id: $id) {
    rank
    weightCategory
    mode
    id
    category
    playerOnEvent {
      player {
        firstName
        lastName
        profile {
          pictureThumbnail
        }
      }
    }
  }
}
`;
