import { gql } from '@apollo/client';

export const AllUsersQuery = gql`
  query AllUsers {
    allUsers {
      firstName
      lastName
      email
      role
      status
      profile {
        gender
        dateOfBirth
        license
        weights {
          value
        }
        files {
          id
        }
      }
    }
  }
`;

export const FindOneUserQuery = gql`
  query FindOneUser($email: String!) {
    findOneUser(email: $email) {
      id
      firstName
      lastName
      email
      status
      role
      profile {
        id
        bio
        dateOfBirth
        license
        grade
        locationOfBirth
        address
        phone
        pictureThumbnail
        gender
        nationality
        rlType
        rlFirstName
        rlLastName
        rlAddress
        rlPhone
        rlEmail
        weights {
          id
          value
          createdAt
        }
        files {
          id
          name
        }
      }
    }
  }
`;
