import { useNavigation } from '@react-navigation/native';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Appbar, Chip, IconButton, List, ProgressBar,
  Searchbar,
  Text,
} from 'react-native-paper';
import { useMutation, useQuery } from '@apollo/client';
import { NativeBaseProvider, View } from 'native-base';
import { useToast } from 'react-native-toast-notifications';
import { styles } from '../Common.style';
import { AllUsersQuery } from '../../gql/user/query';
import { AGE_CATEGORY, ROLE_NAMES, SCREEN_NAMES } from '../../utils/constant';
import { DeleteUserMutation } from '../../gql/user/mutation';
import { AuthContext } from '../../context/AuthContext';
import { ModalContext } from '../../context/ModalContext';
import ConfirmationModal from '../../modals/Confirmation/ConfirmationModal';
import ManageFiltersModal from '../../modals/ManageFilters';
import { computeCategorie, computeWeightBasedOnCategorie } from '../../utils/utils';

function ManageUsers() {
  const navigation = useNavigation();
  const toast = useToast();
  const { authState } = useContext(AuthContext);
  const {
    displayConfirmationModal,
    toggleConfirmationModal,
    displayFiltersModal,
    toggleFiltersModal,
  } = useContext(ModalContext);

  // Filters
  const [searchQuery, setSearchQuery] = useState('');
  const filterCategories = useRef([]);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const { data, loading, refetch } = useQuery(AllUsersQuery);
  const [deleteOneUser, { error: deleteError }] = useMutation(DeleteUserMutation, {
    onCompleted: (_data) => {
      toast.show(_data.deleteOneUser.message);
      refetch();
    },
  });

  const deleteUser = (user) => deleteOneUser(
    {
      variables: { email: user.email },
    },
  );

  if (deleteError) toast.show(deleteError.message);

  const isSuperAdmin = authState.role === 'SUPERADMIN';

  useEffect(() => {
    if (data?.allUsers) {
      const _sortedUsers = data?.allUsers
        .map((user) => {
          const categories = user.profile ? computeCategorie(new Date(user.profile.dateOfBirth)) : ['Inconnu'];
          return {
            ...user,
            category: categories[0],
            categories,
          };
        });
      const groupedByCategories = Object.groupBy(_sortedUsers, ({ category }) => category);
      const groupedByCategoriesAndSorted = {};
      Object.values(AGE_CATEGORY)
        .forEach((value) => {
          groupedByCategoriesAndSorted[value.name] = _sortedUsers
            .map((user) => (user.categories?.includes(value.name)
              ? user : undefined))
            .filter((user) => user !== undefined);
        });
      groupedByCategoriesAndSorted.Inconnu = groupedByCategories.Inconnu;
      setFilteredUsers(groupedByCategoriesAndSorted);
      setSortedUsers(groupedByCategoriesAndSorted);
    }
  }, [data]);

  useEffect(() => {
    toggleFiltersModal({
      display: false,
      title: 'Filter les utilisateurs',
      filters: [{
        name: 'Filtrer par catégories',
        component: 'SelectBox',
        type: 'ref',
        multiSelect: true,
        value: filterCategories,
        values: Object.entries(AGE_CATEGORY)
          .map((e) => ({ id: e[0], item: e[1].name })),
      }],
    });
  }, []);

  useEffect(() => {
    // Apply filters
    if (filterCategories.current.length > 0) {
      const filtered = Object.keys(sortedUsers)
        .filter((key) => filterCategories.current.map((f) => f.item).includes(key))
        .reduce((obj, key) => {
          obj[key] = sortedUsers[key];
          return obj;
        }, {});
      setFilteredUsers(filtered);
    }
  }, [displayFiltersModal.display]);

  return (
    <View style={styles.container}>
      {loading && <ProgressBar />}
      <Appbar.Header>
        <Appbar.Content title="Liste des utilisateurs" />
        <Appbar.Action
          icon="account-plus"
          onPress={() => navigation.navigate(SCREEN_NAMES.INVITE)}
        />
      </Appbar.Header>
      {displayConfirmationModal.display && <ConfirmationModal />}
      <View>
        <Searchbar
          placeholder="Rechercher un utilisateur"
          onChangeText={(value) => setSearchQuery(value?.toLowerCase())}
          value={searchQuery}
        />
        <View style={styles.right}>
          <IconButton icon="sort" onPress={toggleFiltersModal} />
        </View>
        {displayFiltersModal.display && <ManageFiltersModal />}
        {Object.keys(filteredUsers).map((category) => (
          <List.Section>
            <List.Subheader>
              {category}
              {' - '}
              {filteredUsers[category]?.length}
              {' '}
              {filteredUsers[category] === undefined ? 'Aucun compétiteur'
                : filteredUsers[category]?.length === 1 ? 'compétiteur' : 'compétiteurs'}
            </List.Subheader>
            {filteredUsers[category]?.filter((u) => {
              // search bar
              const matchSearchBar = u.firstName?.toLowerCase().includes(searchQuery)
            || u.lastName?.toLowerCase().includes(searchQuery);
              return matchSearchBar;
            })?.map((d) => {
              const weight = d.categories.length > 0 && d.profile?.weights.length > 0
                ? computeWeightBasedOnCategorie(
                  d.profile.weights[d.profile.weights.length - 1].value,
                  d.profile.gender,
                  d.categories[0],
                ) : undefined;
              return (
                <List.Item
                  key={d.id}
                  title={`${d.firstName} ${d.lastName}`}
                  description={(props) => (
                    <div>
                      <Text {...props}>
                        {d.email}
                        {'\n'}
                      </Text>
                      <Text {...props} style={{ fontWeight: 'bold' }}>
                        {d.profile?.license ?? 'Aucune licence'}
                      </Text>
                    </div>
                  )}
                  left={(props) => (
                    <>
                      {d.role === ROLE_NAMES.SUPERADMIN ? <IconButton {...props} icon="crown" />
                        : d.role === ROLE_NAMES.COACH ? <IconButton {...props} icon="account-hard-hat" />
                          : (
                            <>
                              {d.status === 'COMPETITOR' && <IconButton {...props} icon="karate" />}
                              {d.status === 'ACTIVE' && <IconButton {...props} icon="account" />}
                              {d.status === 'INACTIVE' && <IconButton {...props} icon="progress-clock" />}
                              {d.status === 'REVIEW_PENDING' && <IconButton {...props} icon="account-reactivate" />}
                            </>
                          )}
                    </>
                  )}
                  right={(props) => (
                    <>
                      {weight && (
                      <Chip {...props}>
                        {weight}
                        kg
                      </Chip>
                      )}
                      {d.status !== 'INACTIVE'
                        ? <IconButton {...props} icon="eye" onPress={() => navigation.navigate({ name: SCREEN_NAMES.PROFILE, params: { email: d.email } })} />
                        : null}
                      {isSuperAdmin && d.role !== ROLE_NAMES.SUPERADMIN && (
                      <IconButton
                        {...props}
                        icon="delete"
                        onPress={() => toggleConfirmationModal({
                          title: 'Confirmation',
                          message: 'Voulez-vous vraiment supprimer définitivement l\'utilisateur ? Cette action est irréversible.',
                          handleSubmit: async () => {
                            await deleteUser(d);
                          },
                        })}
                      />
                      )}
                    </>
                  )}
                />
              );
            })}
          </List.Section>
        ))}
      </View>
    </View>
  );
}

export default function ManageUsersScreen() {
  return (
    <NativeBaseProvider>
      <ManageUsers />
    </NativeBaseProvider>
  );
}
